<template>
  <div class="container-fluid bg-white p-5 rounded-lg">
    <div class="col-12 d-flex pl-0">
      <p class="my-auto mr-auto">
        <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

        <span style="font-size: 22px; color: #7f7f7f" class="font-weight-light">
          Fiche objectif:
        </span>
        <span class="font-weight-bold text-black" style="font-size: 25px">{{
          OBJECTIF.libelle
        }}</span>
      </p>

      <div class="row mb-3">
        <div class="col-12 px-0">
          <modal-actions
            @close="
              $router.go($store.getters['routerHistory/fromCreation'] ? -2 : -1)
            "
          />

          <!-- {{ $store.getters["routerHistory/fromCreation"] }}
          <modal-actions @close="$router.go(-2)" /> -->
        </div>
      </div>
    </div>
    <!-- {{ OBJECTIF.kpis }} -->
    <div id="add-page" class="row col-12 p-0 mx-auto">
      <div class="col-md-6 col-12 p-1">
        <!-- INFORMATION  -->
        <div class="col-12 shdow h-100">
          <div class="col-12 p-0 d-flex">
            <p
              class="h4 text-secondary mr-auto"
              style="
                margin-top: 6.656px !important ;
                margin-bottom: 6.656px !important;
              "
            >
              Informations générales
            </p>
            <button
              v-if="
                (isAdmin ||
                  isSuper ||
                  isManager ||
                  isArchitect ||
                  isPo ||
                  isFinance) &&
                (!editMode || editing !== 'info')
              "
              class="btn p-0 border-0 bg-white"
            >
              <i
                @click="(editing = 'info'), (editMode = !editMode)"
                class="mdi mdi-file-document-edit-outline"
                style="font-size: 35px; color: #007bff; cursor: pointer"
              ></i>
            </button>
            <div
              v-else-if="
                isAdmin ||
                isSuper ||
                isManager ||
                isArchitect ||
                isPo ||
                isFinance
              "
              class="my-auto mx-0 d-flex p-0"
            >
              <b-button
                type="submit"
                style="width: 90px"
                class="btn btn-secondary rounded-pill"
                variant="secondary"
                size="sm"
                pill
                @click="updateItem"
                >Confirmer</b-button
              >
              <b-button
                @click="editMode = !editMode"
                style="background-color: #adb5bd; border-color: #adb5bd"
                pill
                size="sm"
                >Annuler</b-button
              >
            </div>
          </div>
          <div class="col-12 p-0">
            <hr class="bg-secondary mb-3 mt-1" />
          </div>
          <div
            class="col-12 p-0 my-1 p-0 mx-0"
            v-show="!editMode || editing !== 'info'"
          >
            <p style="color: black">
              Objectif stratégique :
              <span class="font-weight-bold" style="color: black">{{
                OBJECTIF.libelle
              }}</span>
            </p>
            <p style="color: black">
              Vision :
              <span
                v-if="OBJECTIF.vision"
                class="font-weight-bold"
                style="color: black"
                >{{ OBJECTIF.vision.libelle }}</span
              >
            </p>

            <p style="color: black">
              Organisation responsable :
              <span
                v-if="OBJECTIF.organisationResp"
                class="font-weight-bold"
                style="color: black"
                >{{ OBJECTIF.organisationResp.libelle }}</span
              >
            </p>
            <p style="color: black">
              Responsable :
              <span
                v-if="OBJECTIF.responsable"
                class="font-weight-bold"
                style="color: black"
                >{{
                  OBJECTIF.responsable.firstname +
                  " " +
                  OBJECTIF.responsable.lastname
                }}</span
              >
              <span v-else>-</span>
            </p>
          </div>
          <div v-show="editMode">
            <div>
              <b-form-group
                label="Libellé:"
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <b-form-input
                  class="font-weight-normal"
                  placeholder="Libellé"
                  v-model="newObjectif.libelle"
                  :class="{
                    'is-invalid':
                      $v.newObjectif.libelle.$error &&
                      $v.newObjectif.libelle.$dirty,
                  }"
                />

                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newObjectif.libelle.$dirty"
                >
                  {{
                    !$v.newObjectif.libelle.required
                      ? "Champ obligatoire"
                      : !$v.newObjectif.libelle.minLength
                      ? `Le champ doit contenir au moins ${$v.newObjectif.libelle.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Vision:"
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newObjectif.vision.$error &&
                      $v.newObjectif.vision.$dirty,
                  }"
                >
                  <v-select
                    class="font-weight-normal form-control p-0 bg-transparent"
                    style="padding-right: 0 !important ; border: 1px"
                    placeholder="Vision"
                    v-model="newObjectif.vision"
                    :options="visions"
                    label="text"
                    :reduce="(vision) => vision.value"
                    :class="{
                      'is-invalid':
                        $v.newObjectif.vision.$error &&
                        $v.newObjectif.vision.$dirty,
                    }"
                    @input="visionChanged"
                  />
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newObjectif.vision.$dirty"
                >
                  {{
                    !$v.newObjectif.vision.required ? "Champ obligatoire" : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>
            </div>

            <div>
              <b-form-group
                label="Organisation:"
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newObjectif.organisationResp.$error &&
                      $v.newObjectif.organisationResp.$dirty,
                  }"
                >
                  <v-select
                    class="font-weight-normal form-control p-0 bg-transparent"
                    style="padding-right: 0 !important ; border: 1px"
                    placeholder="Organisation"
                    v-model="newObjectif.organisationResp"
                    :options="organisations"
                    label="text"
                    disabled
                    :reduce="(organisation) => organisation.value"
                    :class="{
                      'is-invalid':
                        $v.newObjectif.organisationResp.$error &&
                        $v.newObjectif.organisationResp.$dirty,
                    }"
                  />
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newObjectif.organisationResp.$dirty"
                >
                  {{
                    !$v.newObjectif.organisationResp.required
                      ? "Champ obligatoire"
                      : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Responsable:"
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                >
                  <!-- :class="{
                    'is-invalid':
                      $v.newObjectif.responsable.$error &&
                      $v.newObjectif.responsable.$dirty,
                  }" -->
                  <v-select
                    label="text"
                    class="font-weight-normal form-control p-0 bg-transparent"
                    style="padding-right: 0 !important ; border: 1px"
                    placeholder="Responsable"
                    v-model="newObjectif.responsable"
                    :options="responsables"
                    :reduce="(responsable) => responsable.value"
                  />
                </div>
                <!-- ERROR MESSAGES -->
                <!-- <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newObjectif.responsable.$dirty"
                >
                  {{ !$v.newObjectif.responsable.required ? "champ obligatoire" : "" }}
                </span> -->
                <!--  -->
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 p-1">
        <!-- PROGRRESS -->
        <div class="col-12 shdow h-100">
          <div style="margin: 8px 0">
            <span class="h4 text-secondary">Avancement</span>
          </div>
          <hr class="mt-1 mb-3 bg-secondary" />

          <p style="color: black" class="d-flex col-12 p-0">
            Avancement :
            <span
              class="font-weight-bold col-6 p-0 my-auto"
              style="color: black"
            >
              <b-progress
                class="col-12 p-0 ml-1"
                max="100"
                show-progress
                height="15px"
              >
                <b-progress-bar
                  :value="OBJECTIF.avancement"
                  :label="`${OBJECTIF.avancement}%`"
                  :style="{ backgroundColor: variant(OBJECTIF.avancement) }"
                ></b-progress-bar> </b-progress
            ></span>
          </p>
          <p style="color: black">
            Alignement :
            <span
              v-if="OBJECTIF.feuilleRoutes"
              class="font-weight-bold"
              style="color: black"
              >{{ OBJECTIF.feuilleRoutes.length }}</span
            >
          </p>
        </div>
      </div>
    </div>
    <hr />

    <div class="row col-12 mx-auto p-0">
      <div class="col-12 px-0">
        <b-tabs
          v-model="activeTab"
          @activate-tab="
            (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
          "
          class="tab-solid tab-solid-primary"
        >
          <b-tab title="Feuilles de Route">
            <FdrTable
              :disabled="
                !(isAdmin || isSuper || isManager || isArchitect || isPo)
              "
            />
          </b-tab>
          <b-tab title="KPI">
            <KpiTable
              :disabled="
                !(
                  isAdmin ||
                  isSuper ||
                  isManager ||
                  isArchitect ||
                  isPo ||
                  isFinance
                )
              "
              :kpis="OBJECTIF.kpis"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import FdrTable from "./components/FdrTable";
import KpiTable from "./components/KpiTable";
import { mapGetters } from "vuex";
import moment from "moment";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import Swal from "sweetalert2";

export default {
  components: {
    ModalActions,
    FdrTable,
    KpiTable,
  },
  data() {
    return {
      activeTab: this.$store.state.tabs.activeTab,

      editMode: false,
      editing: "",
      showAddObjectifsModal: false,
      disable: false,
      newObjectif: {
        libelle: "",
        responsable: "",
        vision: "",
        organisationResp: "",
      },

      vision: {
        libelle: null,
        description: null,
        organisation: null,
        responsable: null,
        objectifs: [],
        progress: 0,
        startedAt: moment().format("DD/MM/YYYY"),
        status: null,
      },
      objectifs: [],

      selectedVisionItem: { libelle: "" },
      selectedGoals: [],
      desactivateAllFileModifications: false,
    };
  },
  validations: {
    newObjectif: {
      libelle: {
        required,
        minLength: minLength(5),
      },
      vision: {
        required,
      },
      // responsable: {
      //   required,
      // },
      organisationResp: {
        required,
      },
    },
  },
  methods: {
    datePickerFormat,
    variant: function (progress) {
      if (progress <= 20) return "#f77f00";
      else if (progress >= 71) return "#2A8947";
      else if (progress >= 21 && progress <= 70) return "#fed713";
      return "default";
    },
    updateItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("objectif/updateObjectif", {
            ...this.newObjectif,
            responsable: this.newObjectif.responsable || null,
          })
          .then(() => {
            this.$store
              .dispatch("objectif/fetchObjectif", this.$route.params.id)
              .then(() => {
                Swal.fire("L'objectif est mis à jour !", "", "success");
                this.editMode = false;
              });
          });
      }
    },
    visionChanged() {
      if (this.newObjectif.vision) {
        var vision = this.VISIONS.find(
          (vision) => vision["@id"] === this.newObjectif.vision
        );
        window.console.log(vision);
        if (vision) {
          this.newObjectif.organisationResp = vision.organisation["@id"];
        }
      } else {
        this.newObjectif.organisationResp = null;
      }
    },
  },

  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store
      .dispatch("objectif/fetchObjectif", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("vision/fetchAllData");
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("objectif", ["OBJECTIF"]), //get current vision
    ...mapGetters("vision", ["VISIONS"]),
    ...mapGetters([
      "isAdmin",
      "isSuper",
      "isManager",
      "isArchitect",
      "isPo",
      "isFinance",
    ]),

    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: ` ${responsable.firstname}  ${responsable.lastname}`,
      }));
    },
    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },
    visions() {
      return this.VISIONS.map((vision) => ({
        value: vision["@id"],
        text: vision.libelle,
      }));
    },
  },
  watch: {
    OBJECTIF() {
      this.newObjectif = {
        id: this.OBJECTIF.id,
        libelle: this.OBJECTIF.libelle,
        vision: this.OBJECTIF.vision["@id"],
        organisationResp: this.OBJECTIF.organisationResp["@id"],
        responsable: this.OBJECTIF.responsable
          ? this.OBJECTIF.responsable["@id"]
          : null,
      };
    },
  },
};
</script>

<style scoped>
#mycontainer {
  height: 70vh;
}
</style>
